import React, { useContext, useState, useEffect } from 'react'
import { css } from '@emotion/core'
import { useWindowSize } from 'react-use'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import 'react-dates/initialize'

import { AccountContext } from '@/contexts/account'

import {
  mainContainerHeaderStyle,
  headerBarLeftWrapperStyle,
  tableHeaderThStyle,
  tableBodyTdStyle,
  tableBodyTdStyleRead,
  tableBodyTdStyleUnread,
} from '@/constants/layout'

import {
  ReservationHeaderButtonStyle,
  guestPictureStyle,
  guestNameUnreadEnStyle,
  guestNameUnreadJaStyle,
  guestNameReadEnStyle,
  guestNameReadJaStyle,
  guestNameStyle,
  guestNameKanaStyle,
  guestIdStyle,
  reservationRoomNumbersStyle,
  reservationRoomNumberStyle,
  selfCheckinTimeStyle,
  dataOtherMenuStyle,
  deleteButtonStyle,
  deleteButtonTextStyle,
  modalContainerStyle,
  modalBodyContainerStyle,
  deleteModalContentPStyle,
  deleteModalContentValueStyle,
  deleteModalContentCloseButtonStyle,
  deleteModalContentDeleteButtonStyle,
  reloadWrapperStyle,
} from '@/components/pages/accommodation-management/list-layout'

// apis
import { updateAdminCheckinList, updateAdminCheckin, updateCheckinReception } from '@/apis/aipass'

// components
import { Button } from '@/components/atoms/button'
import { ReloadButton } from '@/components/atoms/reload-button'
import { AccommodationPaging } from '@/components/molecules/accommodation-paging'
import { DetailedReserchPanel } from '@/components/pages/accommodation-management/_checkin/detailed-reserch-panel'

// libs
import { dividedRoomNumber, dividedTypeName } from '@/libs/divide-list'
import { useErrorHandler } from '@/hooks/use-error-handler'

// models
import { ApprovedStatusType } from '@/models/reservation-approved-status'
import { getSearchedConditions } from '../search-condition'
import { useSearchedConditions } from '@/hooks/use-search-condition'
import { hasAnyReservationPlugin } from '@/libs/plugins'

type ContainerProps = {
  checkinList: any
  setPage: (v: number) => void
  page: number
  refresh: () => void
  hasList: number
  listTotal: number
  limit: number
  pagingIsDisabled: boolean
  isStatusApproved
  setIsStatusApproved
  onClickReserch: any
  onClickClearReserch: any
  detailedReserch: any
  setDetailedReserch: any
  setIsLoading: (bool) => void
}

export const CheckinList: React.FC<ContainerProps> = ({
  checkinList,
  page,
  setPage,
  refresh,
  hasList,
  listTotal,
  limit,
  pagingIsDisabled,
  isStatusApproved,
  setIsStatusApproved,
  onClickReserch,
  onClickClearReserch,
  detailedReserch,
  setDetailedReserch,
  setIsLoading,
}) => {
  const { t, i18n } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const { plugins } = useContext<any>(AccountContext)
  const windows = useWindowSize()
  const [visibleDeleteButtonIndex, setVisibleDeleteButtonIndex] = useState<number>(-1)
  const [isDeleteVisible, setIsDeleteVisible] = useState<boolean>(false)
  const [isDropDownReserchPanelOpen, setIsDropDownReserchPanelOpen] = useState<boolean>(false)
  const [checkedStatusApproved] = useState<boolean>(false)
  const [selectDeleteCheckinId, setSelectDeleteCheckinId] = useState<string>('')
  const [checkedCheckinIds, setCheckedCheckinIds] = useState<object>({})
  const [checkedAllFlag, setCheckedAllFlag] = useState<boolean>(false)
  const [checkedTotalCount, setCheckedTotalCount] = useState<number>(0)

  const searchedConditions = getSearchedConditions()
  const { SearchedConditionValues } = useSearchedConditions({ searchedConditions })

  useEffect(() => {
    setCheckedCheckinIds(checkedCheckinIds)
  }, [checkedCheckinIds])

  const _onDelete = async () => {
    if (selectDeleteCheckinId) {
      await updateAdminCheckinList({ checkinId: selectDeleteCheckinId, mode: 'delete' })
      window.location.reload()
      setSelectDeleteCheckinId('')
    }
  }

  const _visibleDeleteButton = index => {
    setVisibleDeleteButtonIndex(visibleDeleteButtonIndex === index ? -1 : index)
  }

  // Checkbox when status update (single) is selected
  const handleCheckboxChange = (e, index) => {
    // Empty if already checked
    let newCheckinId = ''
    if (e.target.value !== checkedCheckinIds[index]) {
      newCheckinId = e.target.value
      const createCheckinIds = { ...checkedCheckinIds, [index]: newCheckinId }
      setCheckedCheckinIds(createCheckinIds)
    }

    if (e.target.value === checkedCheckinIds[index]) {
      delete checkedCheckinIds[index]
      const createCheckinIds = { ...checkedCheckinIds, [index]: newCheckinId }
      setCheckedCheckinIds(createCheckinIds)
    }
    // EX) When checking 0 and 2 (when checking 1 and unchecking) {0: '123', 1: '', 2: '234'}
  }

  // Checkbox when status update (whole) is selected
  const handleAllCheckboxChange = index => {
    if (!checkedAllFlag) {
      const allCheckinIds = checkinList.map((v: any) => {
        return v.id
      })
      setCheckedAllFlag(true)
      setCheckedCheckinIds(allCheckinIds)
    } else {
      setCheckedAllFlag(false)
      setCheckedCheckinIds([])
    }
  }

  // Push booking id and approvedStatus on status update
  const onSubmit = async () => {
    try {
      setIsLoading(true)
      // Format the array, excluding if it is empty
      const formattedCheckedCheckinIds: string[] = []

      Object.keys(checkedCheckinIds).forEach(function (key) {
        if (checkedCheckinIds[key] !== '') {
          formattedCheckedCheckinIds.push(checkedCheckinIds[key])
        }
      })

      await updateCheckinReception(formattedCheckedCheckinIds, ApprovedStatusType.Checkin, 'accommodationList')
      await updateAdminCheckin({ checkinId: formattedCheckedCheckinIds, approvedStatus: ApprovedStatusType.Stay })
    } catch (error) {
      errorHandler(error)
    } finally {
      refresh()
    }
  }

  // Number of status change checks
  useEffect(() => {
    let count = 0
    Object.keys(checkedCheckinIds).forEach(key => {
      if (checkedCheckinIds[key] !== '') {
        count++
      }
    })
    setCheckedTotalCount(count)
    if (count === 0) {
      setCheckedAllFlag(false)
    }
    if (count > 0 && count === hasList) {
      setCheckedAllFlag(true)
    } else {
      setCheckedAllFlag(false)
    }
  }, [checkedCheckinIds])

  return (
    <>
      <div css={mainContainerHeaderStyle}>
        <div css={headerBarLeftWrapperStyle}>
          {!isStatusApproved && (
            <>
              <div>
                <Button
                  buttonType={4}
                  width={i18n.language === 'en' ? 118 : 90}
                  height={32}
                  fontSize={12}
                  onClick={() => setIsDropDownReserchPanelOpen(!isDropDownReserchPanelOpen)}
                >
                  {t('Detailed search')}
                </Button>
                {isDropDownReserchPanelOpen && (
                  <DetailedReserchPanel
                    onClickReserch={onClickReserch}
                    onClickClearReserch={onClickClearReserch}
                    setIsDropDownReserchPanelOpen={() => setIsDropDownReserchPanelOpen(!isDropDownReserchPanelOpen)}
                    detailedReserch={detailedReserch}
                    setDetailedReserch={setDetailedReserch}
                  />
                )}
              </div>
              <div css={reloadWrapperStyle}>
                <ReloadButton onClick={() => refresh()} />
              </div>

              <SearchedConditionValues />
            </>
          )}
          {isStatusApproved && (
            <>
              <div css={selectedNumberStyle}>
                {checkedTotalCount} {t('item selected')}
              </div>
              <Button
                buttonType={3}
                width={128}
                height={32}
                fontSize={12}
                marginLeft={24}
                onClick={() => {
                  onSubmit()
                  setIsStatusApproved(false)
                  setCheckedCheckinIds({})
                  setCheckedAllFlag(false)
                }}
              >
                {t('Change during stay')}
              </Button>
              <Button
                buttonType={4}
                width={102}
                height={32}
                fontSize={12}
                marginLeft={8}
                onClick={() => {
                  setIsStatusApproved(false)
                  setCheckedCheckinIds({})
                  setCheckedAllFlag(false)
                }}
              >
                {t('Cancel')}
              </Button>
            </>
          )}
        </div>
        <div css={headerBarLeftWrapperStyle}>
          <AccommodationPaging page={page} setPage={setPage} total={listTotal} limit={limit} isDisabled={pagingIsDisabled} />
          <div css={ReservationHeaderButtonStyle}></div>
        </div>
      </div>
      <div css={{ overflow: 'auto', height: windows.height - 218 }}>
        <table css={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              {isStatusApproved && (
                <th css={[tableHeaderThStyle, { paddingLeft: 32 }]}>
                  <input
                    id="checkboxAll"
                    alt="checkbox"
                    type="image"
                    src={require(`@/static/images/check-box${checkedAllFlag === true ? '' : '_off'}.svg`)}
                    onClick={index => handleAllCheckboxChange(index)}
                  />
                </th>
              )}
              <th css={[tableHeaderThStyle, !isStatusApproved ? { paddingLeft: 32 } : {}]}>{t('Full name')}</th>
              <th css={tableHeaderThStyle}>{t('Checkin ID')}</th>
              <th css={tableHeaderThStyle}>{t('Room type')}</th>
              <th css={tableHeaderThStyle}>{t('Room number')}</th>
              <th css={tableHeaderThStyle}>{t('Date of stay')}</th>
              <th css={tableHeaderThStyle}>{t('Arrival times')}</th>
              <th css={tableHeaderThStyle}></th>
            </tr>
          </thead>
          <tbody>
            {checkinList &&
              checkinList.map((v: any, index) => {
                if (!v) return null
                return (
                  <Link
                    key={index}
                    css={[
                      v.checkinRead ? tableBodyTdStyleRead : tableBodyTdStyleUnread,
                      { display: 'table-row', ':hover': { color: '#000' } },
                    ]}
                    to={{
                      pathname: `/checkin/${v.id}`,
                      search: `${location.search}&detailTabType=reservationInfo`,
                      state: { fromAccommodationManagement: true },
                    }}
                  >
                    {isStatusApproved && (
                      <td css={[tableBodyTdStyle, { paddingLeft: 32 }]} onClick={e => e.stopPropagation()}>
                        <input
                          id="checkinId"
                          alt="checkboxList"
                          value={v.id}
                          type="image"
                          src={require(
                            `@/static/images/check-box${checkedCheckinIds[index] === v.id || checkedStatusApproved ? '' : '_off'}.svg`,
                          )}
                          onClick={e => {
                            e.preventDefault()
                            handleCheckboxChange(e, index)
                          }}
                        />
                      </td>
                    )}
                    <td css={[tableBodyTdStyle, !isStatusApproved ? { paddingLeft: 32 } : {}]}>
                      <div css={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          src={v.guest.picture ? v.guest.picture : require('@/static/images/user.svg')}
                          css={[guestPictureStyle, { marginRight: 32 }]}
                        />
                        <div
                          css={() => {
                            if (!v.checkinRead) {
                              return i18n.language === 'en' ? guestNameUnreadEnStyle : guestNameUnreadJaStyle
                            } else {
                              return i18n.language === 'en' ? guestNameReadEnStyle : guestNameReadJaStyle
                            }
                          }}
                        >
                          <div css={guestNameKanaStyle}>{v.guest.nameKana ? v.guest.nameKana : '-'}</div>
                          <div css={guestNameStyle}>{v.guest.name ? v.guest.name : '-'}</div>
                        </div>
                      </div>
                    </td>
                    <td css={[tableBodyTdStyle, { maxWidth: 200 }]}>
                      <div css={guestIdStyle}>{v.checkinCode}</div>
                    </td>
                    <td css={[tableBodyTdStyle, { maxWidth: 200 }]}>
                      <div css={reservationRoomNumbersStyle}>
                        <div css={reservationRoomNumberStyle}>{dividedTypeName(v.rooms)}</div>
                      </div>
                    </td>
                    <td css={[tableBodyTdStyle, { maxWidth: 130 }]}>
                      <div css={[reservationRoomNumberStyle, { width: '100%', paddingRight: 16, marginRight: 0 }]}>
                        {dividedRoomNumber(v.rooms)}
                      </div>
                    </td>
                    <td css={[tableBodyTdStyle, { minWidth: 111 }]}>
                      <div css={selfCheckinTimeStyle}>
                        {!hasAnyReservationPlugin(plugins) && v.checkinTime ? (
                          <>
                            <span css={checkinDateYearStyle}>{dayjs(v.checkinDate).format(t('YYYY'))}</span>
                            {dayjs(v.checkinTime).format(t('MM-DD'))}
                            <span style={{ paddingLeft: 5 }}>1{t('Night')}</span>
                          </>
                        ) : hasAnyReservationPlugin(plugins) && v.checkinDate ? (
                          <>
                            <span css={checkinDateYearStyle}>{dayjs(v.checkinDate).format(t('YYYY'))}</span>
                            {dayjs(v.checkinDate).format(t('MM-DD'))}
                            <span style={{ paddingLeft: 5 }}>
                              {v.nights ? v.nights : 0}
                              {t('Night')}
                            </span>
                          </>
                        ) : (
                          '-'
                        )}
                      </div>
                    </td>
                    <td css={[tableBodyTdStyle]}>
                      <div css={selfCheckinTimeStyle}>{v.checkinTime ? dayjs(v.checkinTime).format(t('HH:mm')) : '-'}</div>
                    </td>
                    <td css={[tableBodyTdStyle]} onClick={e => e.stopPropagation()}>
                      <div css={{ display: 'flex', alignItems: 'center' }}>
                        <Link to={{ pathname: '/tablet-register', search: `?checkinId=${v.id}` }} target="_blank" css={{ marginRight: 8 }}>
                          <img src={require('@/static/images/edit_window.svg')} />
                        </Link>
                        <div
                          onClick={event => {
                            event.preventDefault()
                            _visibleDeleteButton(index)
                          }}
                          className="editCell"
                          css={dataOtherMenuStyle}
                        >
                          <img src={require('@/static/images/icon_other.svg')} />
                          {visibleDeleteButtonIndex === index && (
                            <div
                              className="deleteButton"
                              css={deleteButtonStyle}
                              onClick={() => {
                                setIsDeleteVisible(true)
                                setSelectDeleteCheckinId(v.id)
                              }}
                            >
                              <img src={require('@/static/images/icon_delete.svg')} />
                              <div css={deleteButtonTextStyle}>{t('Delete')}</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                  </Link>
                )
              })}
          </tbody>
        </table>
        {isDeleteVisible && (
          <React.Fragment>
            <div css={modalContainerStyle}></div>
            <div css={modalBodyContainerStyle}>
              <div className="modalContent">
                <div className="title">
                  <p css={deleteModalContentPStyle}>{t('Are you sure you want to delete it')}</p>
                </div>
                <div className="name">
                  <div css={deleteModalContentValueStyle}>{t('Data is permanently deleted')}</div>
                </div>
                <div
                  css={deleteModalContentCloseButtonStyle}
                  onClick={() => {
                    setIsDeleteVisible(false)
                  }}
                >
                  {t('Cancel')}
                </div>
                <button
                  type="submit"
                  name="deleteButton"
                  css={deleteModalContentDeleteButtonStyle}
                  onClick={() => {
                    _onDelete()
                  }}
                >
                  {t('Delete')}
                </button>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </>
  )
}

const selectedNumberStyle = css({
  fontSize: 14,
  fontWeight: 'bold',
  margin: 'auto',
})

const checkinDateYearStyle = css({
  '@media(max-width: 1080px)': {
    display: 'block',
  },
})
