import React, { useState } from 'react'
import dayjs from 'dayjs'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

// models
import { ReservationType } from '@/models/reservation'
import { dividedRoomNumber } from '@/libs/divide-list'
import { ApprovedStatusType } from '@/models/reservation-approved-status'

import i18n from '@/i18n'
import { SelectRelevantModal } from '../select-relevant-modal'
import { SelectCreationModal } from './select-creation-modal'

type Props = {
  reservation: any
  reservations: ReservationType[]
  changeApprovedStatus: (reservationIds, approvedStatus) => void
  bulkChangeApprovedStatus: (approvedStatus) => void
  onClickDeleteReservationCheckin: (checkinId, reservationId) => void
  checkinReception?: () => void
}

export const approvedStatus = [
  {
    value: ApprovedStatusType.Checkin,
    label: i18n.t('Checkin'),
  },
  {
    value: ApprovedStatusType.Stay,
    label: i18n.t('During your stay'),
  },
  {
    value: ApprovedStatusType.Checkout,
    label: i18n.t('Checkout'),
  },
]

export const ReservationList: React.FC<Props> = ({
  reservations,
  reservation,
  changeApprovedStatus,
  bulkChangeApprovedStatus,
  onClickDeleteReservationCheckin,
  checkinReception,
}) => {
  const { t } = useTranslation()
  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false)
  const [isSingleDropDownOpen, setIsSingleDropDownOpen] = useState<boolean>(false)
  const [singleDropDownStyle, setSingleDropDownStyle] = useState<{ top: number; left: number }>({ top: 0, left: 0 })
  const [isSingleDropDownOpenIndex, setIsSingleDropDownOpenIndex] = useState<number>(0)
  const [isSelectCreationModalOpen, setIsSelectCreationModalOpen] = useState<boolean>(false)
  const [isSearchModalOpen, setIsSearchModalOpen] = useState<boolean>(false)

  const onDeleteReservation = (checkinId, reservationId) => {
    window.confirm(t('Untethered reservation. When released, the reservation is moved to the reservation status.')) &&
      onClickDeleteReservationCheckin(checkinId, reservationId)
  }

  const onShowSingleDropDown = (e: React.MouseEvent<HTMLDivElement>, key: number) => {
    const item = e.currentTarget.getBoundingClientRect()
    setSingleDropDownStyle({ top: item.bottom, left: item.left })

    setIsSingleDropDownOpen(!isSingleDropDownOpen)
    setIsSingleDropDownOpenIndex(key)
  }

  return (
    <>
      <div css={reservationListContainer}>
        <div css={reservationListSubject}>
          <div css={reservationListTitleStyle}>
            <p>{t('Reservation list')}</p>
            <p
              style={{ display: 'flex', alignItems: 'center', color: '#F2A40B', fontSize: 12, gap: 7, cursor: 'pointer' }}
              onClick={() => setIsSelectCreationModalOpen(true)}
            >
              <img src={require('@/static/images/link_add_orange.svg')} alt="" />
              {t('Add Reservations')}
            </p>
          </div>
          <div css={reservationButtonStyle} onClick={() => setIsDropDownOpen(!isDropDownOpen)}>
            {t('Bulk change')}
            <img css={arrowStyle} src={require('@/static/images/arrow_yellow.svg')} alt="" />
            {isDropDownOpen && (
              <div css={dropDownStyle}>
                <ul>
                  <li css={dropDownContentStyle} onClick={() => bulkChangeApprovedStatus(ApprovedStatusType.Checkin)}>
                    <p css={dropDownTitleStyle}>{t('Checkin')}</p>
                  </li>
                  <li css={dropDownContentStyle} onClick={() => bulkChangeApprovedStatus(ApprovedStatusType.Stay)}>
                    <p css={dropDownTitleStyle}>{t('During your stay')}</p>
                  </li>
                  <li css={dropDownContentStyle} onClick={() => bulkChangeApprovedStatus(ApprovedStatusType.Checkout)}>
                    <p css={dropDownTitleStyle}>{t('Checkout')}</p>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div style={{ overflowX: 'auto', borderRadius: '0px 0px 10px 10px' }}>
          <table>
            <thead>
              <tr css={listTitle}>
                <th css={reservationIdStyle}>{t('Accommodation ID')}</th>
                <th css={reservationDateStyle}>{t('Date of stay')}</th>
                <th css={reservationNightStyle}>{t('Number of nights')}</th>
                <th css={reservationUserStyle}>{t('Guest')}</th>
                <th css={roomNumberStyle}>{t('Room number')}</th>
                <th css={amountCancelledStyle}>{t('Amount to be collected')}</th>
                <th css={reservationStatusStyle}>{t('Status')}</th>
                <th css={updatedStyle}>{t('Update date')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {!reservations.length && (
                <tr css={listStyle}>
                  <td css={reservationIdStyle}>-</td>
                  <td css={reservationDateStyle}>-</td>
                  <td css={reservationNightStyle}>0{t('Night')}</td>
                  <td css={reservationUserStyle}>-</td>
                  <td css={roomNumberStyle}>-</td>
                  <td css={amountCancelledStyle}>¥0</td>
                  <td css={updatedStyle}>
                    <p>{t('Stay')}：-</p>
                    <p>{t('Out')}：-</p>
                  </td>
                  <td onClick={checkinReception}>
                    <div css={approvedStatusCheckinReceptionStyle}>{t('Check-in reception')}</div>
                  </td>
                </tr>
              )}
              {reservations &&
                reservations.map((reservation, key) => (
                  <tr key={reservation.accommodationId} css={listStyle}>
                    <td css={reservationIdStyle}>{reservation.accommodationId}</td>
                    <td css={reservationDateStyle}>
                      {`${
                        dayjs(reservation.checkinDate).format(t('MM-DD')) ? dayjs(reservation.checkinDate).format(t('MM-DD')) : ''
                      } ${reservation.nights ?? 0}${t('Night')}`}
                    </td>
                    <td css={reservationNightStyle}>
                      {reservation?.checkinDate && reservation?.checkoutDate
                        ? dayjs(reservation?.checkoutDate).diff(dayjs(reservation?.checkinDate), 'day')
                        : 0}
                      {t('Night')}
                    </td>
                    <td css={reservationUserStyle}>{reservation.guestName}</td>
                    <td css={roomNumberStyle}>
                      <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {dividedRoomNumber(reservation.assignedRoom)}
                      </div>
                    </td>
                    <td css={[amountCancelledStyle, { color: reservation?.unpaid ? '#F2A40B' : '' }]}>
                      ¥{Number(reservation?.unpaid || 0).toLocaleString()}
                    </td>
                    <td css={reservationStatusStyle}>
                      <div
                        css={
                          (reservation.approvedStatus === ApprovedStatusType.Checkin && approvedStatusCheckinStyle) ||
                          (reservation.approvedStatus === ApprovedStatusType.Stay && approvedStatusStayStyle) ||
                          (reservation.approvedStatus === ApprovedStatusType.Checkout && approvedStatusCheckoutStyle)
                        }
                        onClick={e => {
                          onShowSingleDropDown(e, key)
                        }}
                      >
                        {reservation.approvedStatus === ApprovedStatusType.Checkin && <>{t('Checkin')}</>}
                        {reservation.approvedStatus === ApprovedStatusType.Stay && <>{t('During your stay')}</>}
                        {reservation.approvedStatus === ApprovedStatusType.Checkout && <>{t('Checkout')}</>}
                        <img css={arrowSelectboxStyle} src={require('@/static/images/select_white.svg')} alt="" />
                        {isSingleDropDownOpen && isSingleDropDownOpenIndex === key && (
                          <div
                            css={[dropDownStyle, { position: 'fixed', left: singleDropDownStyle.left, top: singleDropDownStyle.top + 10 }]}
                          >
                            <ul>
                              {reservation.approvedStatus !== ApprovedStatusType.Checkin && (
                                <li
                                  css={dropDownContentStyle}
                                  onClick={() => changeApprovedStatus(reservation.reservationId, ApprovedStatusType.Checkin)}
                                >
                                  <p css={dropDownTitleStyle}>{t('Checkin')}</p>
                                </li>
                              )}
                              {reservation.approvedStatus !== ApprovedStatusType.Stay && (
                                <li
                                  css={dropDownContentStyle}
                                  onClick={() => changeApprovedStatus(reservation.reservationId, ApprovedStatusType.Stay)}
                                >
                                  <p css={dropDownTitleStyle}>{t('During your stay')}</p>
                                </li>
                              )}
                              {reservation.approvedStatus !== ApprovedStatusType.Checkout && (
                                <li
                                  css={dropDownContentStyle}
                                  onClick={() => changeApprovedStatus(reservation.reservationId, ApprovedStatusType.Checkout)}
                                >
                                  <p css={dropDownTitleStyle}>{t('Checkout')}</p>
                                </li>
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                    </td>
                    <td css={updatedStyle}>
                      <p>
                        {t('Stay')}：{reservation?.statusStayAt ? dayjs(reservation.statusStayAt).format(t('MM-DD HH:mm')) : '-'}
                      </p>
                      <p>
                        {t('Out')}：{reservation?.statusCheckoutAt ? dayjs(reservation.statusCheckoutAt).format(t('MM-DD HH:mm')) : '-'}
                      </p>
                    </td>
                    <td style={{ width: '5%' }}>
                      <img
                        src={require('@/static/images/deleat.svg')}
                        css={deleteStyle}
                        width="24"
                        height="24"
                        onClick={() => onDeleteReservation(reservation.checkinId, reservation.reservationId)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      {isSelectCreationModalOpen && (
        <SelectCreationModal
          onClose={() => setIsSelectCreationModalOpen(false)}
          onSelectSearchRelevant={() => {
            setIsSearchModalOpen(true)
            setIsSelectCreationModalOpen(false)
          }}
        />
      )}
      {isSearchModalOpen && <SelectRelevantModal reservation={reservation} onClose={() => setIsSearchModalOpen(false)} />}
    </>
  )
}

const reservationListContainer = css({
  backgroundColor: '#fff',
  border: '1px solid #CCCCCC',
  borderRadius: '10px',
})

const listTitle = css({
  minWidth: 'max-content',
  color: '#676767',
  fontWeight: 'bold',
  width: '100%',
  fontSize: 12,
  height: 36,
  'th, td': {
    paddingBlock: 10,
    paddingLeft: 32,
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    ':first-of-type': {
      '@media(max-width: 1080px)': {
        paddingLeft: 16,
      },
    },
    ':last-of-type': {
      paddingRight: 32,
      '@media(max-width: 1080px)': {
        paddingRight: 16,
      },
    },
  },
  th: {
    backgroundColor: '#f5f5f5',
  },
})

const listStyle = css(listTitle, {
  backgroundColor: '#fff',
  color: '#272727',
  fontWeight: 'normal',
  fontSize: 14,
  lineHeight: '24px',
  borderRadius: '0px 0px 10px 10px',
  height: 56,
})

const reservationListSubject = css({
  fontSize: 14,
  fontWeight: 'bold',
  padding: '13px 34px 8px 32px',
  display: 'flex',
  justifyContent: 'space-between',
  '@media(max-width: 1080px)': {
    paddingInline: 16,
  },
})

const reservationListTitleStyle = css({
  display: 'flex',
  alignItems: 'center',
  gap: 27,
  margin: 'auto 0px',
})
const reservationIdStyle = css({
  width: '4%',
})

const reservationDateStyle = css({
  width: '12%',
})

const reservationNightStyle = css({
  width: '13%',
})

const reservationUserStyle = css({
  width: '20%',
})

const roomNumberStyle = css({
  width: '12%',
  '@media(max-width: 1080px)': {
    width: '9%',
    div: {
      maxWidth: 90,
    },
  },
})

const reservationStatusStyle = css({
  width: '11%',
})

const amountCancelledStyle = css({
  width: '10%',
})

const updatedStyle = css({
  width: '20%',
  p: {
    lineHeight: 1.2,
  },
})

const reservationStatusSelectboxStyle = css({
  width: 116,
  fontSize: 12,
  fontWeight: 'bold',
  textAlign: 'center',
  letterSpacing: 0,
  borderRadius: 5,
  color: '#fff',
  border: '1px solid #fff',
  position: 'relative',
  paddingRight: 6,
  cursor: 'pointer',
})

const deleteStyle = css({
  width: 24,
})

const arrowSelectboxStyle = css({
  margin: '0 3px',
  position: 'absolute',
  padding: '9px 6px 0px 0px',
  right: 0,
})

const arrowStyle = css({
  position: 'absolute',
  right: 0,
  padding: '4px 8px',
})

const reservationButtonStyle = css({
  width: 116,
  border: '1px solid #E7A73C',
  borderRadius: 5,
  color: '#E7A73C',
  fontSize: 12,
  fontWeight: 'bold',
  textAlign: 'center',
  padding: '6px 0',
  position: 'relative',
  paddingRight: 10,
})

const approvedStatusCheckinReceptionStyle = css(reservationStatusSelectboxStyle, {
  backgroundColor: '#F2A40B',
})
const approvedStatusCheckinStyle = css(reservationStatusSelectboxStyle, {
  backgroundColor: '#F47110',
})
const approvedStatusStayStyle = css(reservationStatusSelectboxStyle, {
  backgroundColor: '#3E85CC',
})
const approvedStatusCheckoutStyle = css(reservationStatusSelectboxStyle, {
  backgroundColor: '#7DC931',
})

const dropDownStyle = css({
  width: 140,
  zIndex: 50,
  backgroundColor: '#FFF',
  position: 'absolute',
  top: 30,
  boxShadow: '0px 3px 10px #0000003d',
  borderRadius: 5,
})

const dropDownContentStyle = css({
  display: 'flex',
  height: 34,
  listStyle: 'none',
  alignItems: 'center',
  padding: '0 16px',
  cursor: 'pointer',
  '&:hover': {
    background: '#f2f2f2',
  },
})

const dropDownTitleStyle = css({
  fontSize: 14,
  fontWeight: 'normal',
  color: '#272727',
})
